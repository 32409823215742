import React from 'react';
// import Dummy from './Dummy';
// import Dummy from './Dummy'
import Dummy from './Dummy'
// import "../components/Styles/Props.css"
import './Props.css'
const Props = () => {
  // const data = {
  //   containerClassName: 'User_design',
  //   week: 'Week 1',
  //   title: 'Introduction to User Experience  Design',
  //   listClassName: 'List_design',
  //   listItems: [
  //     'Understanding the basics',
  //     'Design Principles',
  //     'User Research',
  //     'Wireframing and Prototyping'
  //   ],
  //   subContainerClassName: 'Design_hours',
  //   subTitle: 'Figma Hours',
  //   subContent: 'Get familiar with Figma'
  // };

  const data = [
    {
      week:'Weeks 1-2: Introduction to UI & UX Design',
      weekhead:'Introduction to UI & UX Design',
      sess1:'Sessions 1-2',
      sess1l1:'Understanding the Basics',
      sess1l2:'Design Principles',
      sess1l3:'User Research',
      // sess2:'Session 2: Design Principles',
      sess2l1:'Wireframing and Prototyping',
      // sess2l2:' Typography',
      // sess2l3:' Layout and composition',
      // // sess3:'Session 3: User Research',
      // sess3l1:'Techniques for gathering user insights',
      // sess3l2:'Personas and user journey mapping',
      // // sess4:'Session 4: Wireframing and Prototyping',
      // sess4l1:'Introduction to tools like Sketch, Figma, or Adobe XD',
      // sess4l2:'Hands-on exercises'
    },
    {
      week:'Weeks 3-4: Design Thinking and Problem Solving',
      weekhead:'Design Thinking and Problem Solving',
      sess1:'Sessions 3-4',
      sess1l1:'Introduction to Design Thinking',
      sess1l2:'Ideation Techniques',
      sess1l3:'Prototyping Workshop',
      // sess2:'Mind mapping and affinity diagrams',
      sess2l1:'Usability Testing',
      // sess2l2:' Mind mapping and affinity diagrams',
      // // sess2l3:' Layout and composition',
      // // sess3:'Session 3: User Research',
      // sess3l1:'Rapid prototyping techniques',
      // sess3l2:'Iterative design process',
      // // sess4:'Session 4: Wireframing and Prototyping',
      // sess4l1:'Conduc>ng effec>ve usability tests',
      // sess4l2:'Analysing and implemen>ng feedback'
    },{
      week:'Weeks 5-6: UI Design and Visual Elements',
      weekhead:'UI Design and Visual Elements',
      sess1:'Sessions 5-6 ',
      sess1l1:'Advanced UI Design Principles',
      sess1l2:'Iconography and Imagery',
      sess1l3:'Motion Design',
      sess2l1:'Final Project Kick-of',
    },{
      week:'Weeks 7-8: Portfolio Building and Resume Review',
      weekhead:'Portfolio Building and Resume Review',
      
      sess1:'Sessions 7-8',
      sess1l1:' Crading a Strong Portfolio',
      sess1l2:' Resume Writing Workshop',
      sess1l3:'Portfolio Review and Feedback',

    },{

      week:'Weeks 9-10: Final Project and Presentation',
      weekhead:'Final Project and Presentation',

      sess1:'Sessions 9-10',
      sess1l1:'Project Work',
      sess1l2:'Presentation Skills',
      sess1l3:'Final Project Showcase',
    },
    {

      week:'Other Details',
      weekhead:'Mocks & Interview Preparations',
      sess1:'Additional Information',
      sess1l1:'Class Format:',
      sess1l2:'Evaluation:',
      sess1l3:'Support',
    }

  ]

  return (
    <div className='Propscontainer'>
      <Dummy data={data}/>
      {/* <Dummy  />
      <Dummy {...data} />
      <Dummy {...data} />
      <Dummy {...data} />
      <Dummy {...data} />
      <Dummy {...data} />
      <Dummy {...data} />
      <Dummy {...data} />
      <Dummy {...data} /> */}
    </div>
  );
};

export default Props;
