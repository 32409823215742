import React from 'react';
import './Certificate.css'
import { GrCertificate } from "react-icons/gr";
// import 


const Certificate = () => {
    return (
        <div className='Certificate_learn'>
            <div className="certificate_main">
                <div className="certificate_head">
                    <h1><span>Certificate</span> you will earn</h1>
                </div>

                <div className="certificate_sub">

                    <div className="certificate_start">
                        <div className="cert_sub">

                        <div className="certify_head">
                            <h1>uxmate</h1>
                        </div>
                        <div className="certify_com">
                            <h1>Certificate of<br/>Completion</h1>
                            <p>Congratulations on taking the course towards your next journey in your career. <br /><br />
                            This certificate is proudly awared to</p>
                            <h2>Sunil K</h2>
                            <p className='course'>on successful completion of the <br /> course criteria</p>
                        </div>
                        </div>

                        <div className="cert_sub2">
                        <p><GrCertificate /></p>
                        </div>

                    </div>

                </div>
            </div>

            <div className='certificate_verify'>
                <h1><span>Verifiable </span>certificate of accomplishment</h1>
                <p>Upon completing the this certificate course at uxmate, you'll<br/>
                  receive a verifiable Certificate of Accomplishment that you can<br/>
                  link to from your Résumé and LinkedIn profile.</p>
            </div>

            <div className='footer_last'>
                <div className="logos">
                <p><a href="https://www.facebook.com/">Facebook |</a></p>
                <p><a href="https://twitter.com/UXmateofficial">X |</a></p>
                <p><a href="https://www.instagram.com/uxmateofficial/">Instagram |</a></p>
                <p><a href="https://www.youtube.com/">Youtube </a></p>
                </div>
                <div className='copy'>
                    <p>Copyrights Reserved</p>
                </div>
            </div>
            
        </div>
    );
};

export default Certificate;