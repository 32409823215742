import React from 'react';
import Home from './Pages/Home';
import './App.css'
// import learn from './'
// import Learn from './Pages/Learn';
// import Certificate from './Pages/Certificate';
import Tools from './Pages/Tools'
import Certificate from './Pages/Certificate'
import Addon from './Pages/Addon';
// import Dashboard from './Pages/Dashboard';
// import Props from './Pages/Props'

const App = () => {
  return (

    <div>
      <Home/>
      <Tools/>
      <Addon/>
      <Certificate/>
      {/* <Dashboard/> */}
    </div>
  );
};

export default App;