import React from 'react';
// import "../components/Styles/Dummy.css"
import './Dummy.css'
const Dummy = (props) => {
  return (

    // <div className='Container_main'>

    // <div className="containerClassName">
    //   <p>{props.week}</p>
    //   <h3>{props.title}</h3>
    //   <ul className="listClassName">
    //     {props.listItems.map((item, index) => (
    //       <li key={index}>{item}</li>
    //     ))}
    //   </ul>
    // </div>
    //   <div className="subContainerClassName">
    //     <p>{props.subTitle}</p>
    //     <h4>{props.subContent}</h4>
    //   </div>
    // </div>

    <div>
      <div className="container_load">

      {props.data.map((e,i)=>{
        return(
          <div className='container_main' key={i}>

          <div className="container_sub">
            {e.week && <p>{e.week}</p>}
            {e.sess1 && <h6>{e.sess1}</h6>}
            <ul>
            {e.sess1l1&&<li>{e.sess1l1}</li>}
            {e.sess1l2&&<li>{e.sess1l2}</li>}
            {e.sess1l3&&<li>{e.sess1l3}</li>}
            {e.sess2l1&&<li className='last_p'>{e.sess2l1}</li>}
            {/* {e.sess2l2&&<li>{e.sess2l2}</li>} */}
            {/* {e.sess2l3&&<li>{e.sess2l3}</li>}
            {e.sess3l1&&<li>{e.sess3l1}</li>}
            {e.sess3l2&&<li>{e.sess3l2}</li>}
            {e.sess4l1&&<li>{e.sess4l1}</li>}
            {e.sess4l2&&<li>{e.sess4l2}</li>} */}
            </ul>
  
          </div>
            <div className="container_sub2">
              {e.weekhead && <h6>{e.weekhead}</h6>}
            </div>
          </div>
          
        )
      })}

      </div>



    </div>
  );
};

export default Dummy;
