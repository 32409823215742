import React, { useEffect, useRef, useState } from 'react';
import './Home.css'
// import dis from '../Images/dis.png'
import Design from './Design';
import axios from 'axios';
// import Props from './Pages/Props'
// import Props from './Props';
// import Design from './Pages/Design'
// import Design from './Design'
// import Props from './Props'


// import img1 from '../Pages/Images/img1.png'
// import img2 from '../Pages/Images/img2.png'
// import img3 from '../Pages/Images/img3.png'
// import img4 from '../Pages/Images/img4.png'

import mntr from '../Pages/Images/mntr.png'
import mag from '../Pages/Images/mag.png'
import plan from '../Pages/Images/plan.png'
import pad from '../Pages/Images/pad.png'
import disc from '../Pages/Images/disc.png'

// import uxmate from '../Pages/Images/uxmate_mainlogo.svg'
// import uxmate_svg from '../Pages/Images/uxmate_mainlogo.svg'
// import logo from './Images/logo.svg'


const Home = () => {

    const [data,setdata] = useState({
        First_Name:'',
        Last_Name:'',
        Email:'',
        Phone:''
    })

    const wrong = useRef()

    // const [already,setalready] = useState()

    var [time,settime] = useState(180)

    const btndsbld = useRef()

    // const [pop,setpop] = useState(true)

    const [errfirst,seterrfirst] = useState('')
    const [errlast,seterrlast] = useState('')
    const [erremail,seterremail] = useState('')
    const [errphone,seterrphone] = useState('')

    const inp1 = useRef()
    const inp2 = useRef()
    const inp3 = useRef()
    const inp4 = useRef()


    const formvis = useRef()

    const formopen = ()=>{
        formvis.current.classList.add('vis')
    }
    function formclose(){
        formvis.current.classList.remove('vis')
    }

    function display(e){
        setdata({...data,[e.target.name]:e.target.value})
    }


    function senddata(){

        let valid = true
        
        if(inp1.current.value.trim() === ''){
            seterrfirst('First name is required')
            valid = false
        }
        else{
            seterrfirst('')
            valid = true
        }

        if(inp2.current.value.trim() === ''){
            seterrlast('Last name is required')
            valid = false
        }
        else{
            seterrlast('')
            valid = true
        }

        if (data.Email.trim() === '') {
            seterremail('Email is required')
            valid = false
        } 
        else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.Email)) {
            seterremail('Invalid email format')
            valid = false
        }
        else{
            seterremail('')
            valid = true
        }
        
        if (data.Phone.trim() === '') {
            seterrphone('Phone number is required')
            valid = false
        } else if (!/^\d{10}$/.test(data.Phone)) {
            seterrphone('Invalid phone number')
            valid = false
        }
        else{
            seterrphone('')
            valid = true
          }


          if(valid === true){
            
            axios.post('https://sheet.best/api/sheets/b4135298-2d93-4663-9414-d6daa0707574',data)
            .then(()=>alert('Submit Successfully'))
            setdata({First_Name:'',Last_Name:'',Email:'',Phone:''})
            formvis.current.classList.remove('vis')
          }

    }


    // const [dis,setdis] = useState(false)
    




    useEffect(()=>{

        if(time>1){
            const abc = setInterval(()=>{
                settime(()=>--time)
                
            },1000)
            const bcd = setTimeout(()=>{
                    clearInterval(abc)
                    wrong.current.classList.add('wrong')
                    // btndsbld.current.disabled = true
                    // btndsbld.current.innerText = 'Offer expired'
                    // btndsbld.current.classList.add('dsb')
            },180000)

            return bcd
        }
    },[])



    return (
        <section>
            <div className="home_main">

                <div className="logo_main">
                    {/* <img src={logo} alt="" /> */}
                    {/* <h1>uxmate</h1> */}

                    {/* <img src={uxmate_svg} alt="" /> */}

                    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 605 166" fill="none">
<path d="M136.41 1.29639L93.048 64.9919L133.898 129.216H95.0385L72.5003 94.3301L49.4526 129.216H10.3761L52.5288 64.9919L9.66194 1.77258L9.33813 1.29639C4.73335 1.29639 1 5.02926 1 9.63451V102.03H1.00476C1.10952 132.611 25.5049 158.102 57.9288 164.026L72.6527 141.54L87.1004 163.968C100.858 161.402 113.158 155.302 122.748 146.792C135.658 135.34 143.663 119.521 143.724 102.03V8.6107C143.724 4.57116 140.45 1.29639 136.41 1.29639Z" stroke="#ffffff" strokeWidth="2" strokeMiterlimit="10" fill="#ffffff"/>
<path d="M72.1731 35.9205L94.5304 1.77272L94.8399 1.29652H50.1849L72.1741 35.4424L72.1731 35.4443L50.1826 1.29652H49.8778L50.1826 1.77272L72.1731 35.9205Z" stroke="#ffffff" strokeWidth="2" strokeMiterlimit="10" fill="#ffffff"/>
<path d="M579.706 117.9C573.743 117.9 568.442 116.648 563.804 114.145C559.24 111.568 555.633 108.071 552.982 103.654C550.406 99.2371 549.117 94.1573 549.117 88.415C549.117 83.8506 549.853 79.6911 551.326 75.9365C552.798 72.1819 554.823 68.9427 557.399 66.2188C560.05 63.4212 563.179 61.2863 566.786 59.8139C570.467 58.2679 574.442 57.4949 578.712 57.4949C582.467 57.4949 585.964 58.2311 589.203 59.7035C592.442 61.1022 595.24 63.0531 597.596 65.5562C600.025 68.0593 601.866 71.0408 603.117 74.501C604.442 77.8875 605.068 81.6052 604.994 85.6543L604.884 90.5132H557.51L554.97 81.458H595.056L593.399 83.3353V80.685C593.178 78.2555 592.369 76.0838 590.97 74.1697C589.571 72.2556 587.804 70.7464 585.669 69.6421C583.534 68.5378 581.215 67.9856 578.712 67.9856C574.737 67.9856 571.387 68.7586 568.663 70.3046C565.939 71.777 563.878 73.9856 562.479 76.9304C561.08 79.8016 560.381 83.3721 560.381 87.642C560.381 91.6911 561.228 95.2248 562.921 98.2432C564.614 101.188 567.007 103.47 570.099 105.09C573.191 106.709 576.761 107.519 580.81 107.519C583.681 107.519 586.332 107.041 588.761 106.084C591.264 105.127 593.951 103.397 596.823 100.894L602.565 108.955C600.798 110.722 598.626 112.268 596.05 113.593C593.547 114.918 590.859 115.985 587.988 116.795C585.191 117.532 582.43 117.9 579.706 117.9Z" fill="#ffffff"/>
<path d="M517.982 116.795V44.0222H529.356V116.795H517.982ZM505.945 69.8626V58.8197H542.829V69.8626H505.945Z" fill="#ffffff"/>
<path d="M463.229 117.897C458.444 117.897 454.064 116.572 450.088 113.922C446.186 111.272 443.058 107.664 440.702 103.1C438.346 98.5354 437.168 93.3821 437.168 87.6397C437.168 81.8238 438.346 76.6705 440.702 72.1797C443.131 67.6153 446.37 64.0447 450.419 61.468C454.542 58.8914 459.143 57.603 464.223 57.603C467.241 57.603 470.002 58.0447 472.505 58.9282C475.008 59.8116 477.18 61.0631 479.021 62.6828C480.935 64.2288 482.481 66.0325 483.659 68.0938C484.91 70.1551 485.683 72.3637 485.978 74.7195L483.438 73.8361V58.8177H494.922V116.793H483.438V102.989L486.088 102.216C485.646 104.204 484.726 106.155 483.327 108.069C482.002 109.91 480.272 111.566 478.137 113.038C476.076 114.511 473.757 115.689 471.18 116.572C468.677 117.456 466.027 117.897 463.229 117.897ZM466.211 107.407C469.671 107.407 472.726 106.56 475.376 104.867C478.027 103.173 480.088 100.854 481.56 97.9097C483.106 94.8913 483.879 91.468 483.879 87.6397C483.879 83.8852 483.106 80.5355 481.56 77.5907C480.088 74.6459 478.027 72.3269 475.376 70.6337C472.726 68.9404 469.671 68.0938 466.211 68.0938C462.824 68.0938 459.806 68.9404 457.156 70.6337C454.579 72.3269 452.518 74.6459 450.972 77.5907C449.499 80.5355 448.763 83.8852 448.763 87.6397C448.763 91.468 449.499 94.8913 450.972 97.9097C452.518 100.854 454.579 103.173 457.156 104.867C459.806 106.56 462.824 107.407 466.211 107.407Z" fill="#ffffff"/>
<path d="M339.004 116.798V58.8225H350.489V71.1905L348.39 72.5157C348.979 70.6016 349.9 68.7611 351.151 66.9942C352.476 65.2274 354.059 63.6814 355.9 62.3562C357.814 60.9574 359.838 59.8531 361.973 59.0433C364.182 58.2335 366.427 57.8286 368.709 57.8286C372.022 57.8286 374.93 58.3808 377.433 59.485C379.936 60.5893 381.998 62.2458 383.617 64.4544C385.237 66.6629 386.415 69.4237 387.151 72.7365L385.384 72.2948L386.157 70.4175C386.967 68.7243 388.071 67.1415 389.47 65.6691C390.942 64.1231 392.599 62.7611 394.439 61.5832C396.28 60.4053 398.231 59.485 400.292 58.8225C402.353 58.1599 404.378 57.8286 406.366 57.8286C410.709 57.8286 414.28 58.712 417.077 60.4789C419.948 62.2458 422.083 64.9329 423.482 68.5402C424.955 72.1476 425.691 76.6384 425.691 82.0126V116.798H414.206V82.6752C414.206 79.3623 413.764 76.6752 412.881 74.6138C412.071 72.4789 410.82 70.8961 409.126 69.8654C407.433 68.8347 405.261 68.3194 402.611 68.3194C400.55 68.3194 398.599 68.6875 396.758 69.4237C394.991 70.0862 393.445 71.0433 392.12 72.2948C390.795 73.5464 389.765 75.0188 389.028 76.712C388.292 78.3316 387.924 80.1353 387.924 82.123V116.798H376.439V82.4543C376.439 79.4359 375.998 76.896 375.114 74.8347C374.231 72.6997 372.942 71.0801 371.249 69.9758C369.556 68.8715 367.495 68.3194 365.065 68.3194C363.004 68.3194 361.09 68.6875 359.323 69.4237C357.556 70.0862 356.01 71.0433 354.685 72.2948C353.36 73.4727 352.329 74.9083 351.593 76.6016C350.857 78.2212 350.489 79.9881 350.489 81.9022V116.798H339.004Z" fill="#ffffff"/>
<path d="M315.764 116.797L296.991 92.3917L295.335 90.2936L270.93 58.8213H285.285L303.727 82.8948L305.604 85.3243L329.678 116.797H315.764ZM271.04 116.797L293.899 86.9807L300.635 95.042L284.512 116.797H271.04ZM306.267 88.7476L299.973 80.7967L314.218 58.8213H327.69L306.267 88.7476Z" fill="#ffffff"/>
<path d="M230.678 117.901C226.556 117.901 222.948 116.944 219.856 115.03C216.838 113.116 214.482 110.429 212.789 106.968C211.169 103.508 210.359 99.3856 210.359 94.6003V58.8213H221.734V91.5083C221.734 94.8948 222.249 97.8396 223.28 100.343C224.384 102.772 225.93 104.649 227.918 105.975C229.979 107.3 232.445 107.962 235.316 107.962C237.451 107.962 239.402 107.631 241.169 106.968C242.936 106.232 244.445 105.238 245.697 103.987C247.022 102.735 248.053 101.226 248.789 99.4592C249.525 97.6923 249.893 95.7782 249.893 93.7169V58.8213H261.267V116.797H249.893V104.649L251.881 103.324C250.997 106.048 249.488 108.514 247.353 110.723C245.292 112.932 242.826 114.698 239.954 116.024C237.083 117.275 233.991 117.901 230.678 117.901Z" fill="#ffffff"/>
</svg>
{/* <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 605 166" fill="none" transform="matrix(1, 0, 0, 1, 0, 0)">
<path d="M136.41 1.29639L93.048 64.9919L133.898 129.216H95.0385L72.5003 94.3301L49.4526 129.216H10.3761L52.5288 64.9919L9.66194 1.77258L9.33813 1.29639C4.73335 1.29639 1 5.02926 1 9.63451V102.03H1.00476C1.10952 132.611 25.5049 158.102 57.9288 164.026L72.6527 141.54L87.1004 163.968C100.858 161.402 113.158 155.302 122.748 146.792C135.658 135.34 143.663 119.521 143.724 102.03V8.6107C143.724 4.57116 140.45 1.29639 136.41 1.29639Z" stroke="#ffffff" stroke-width="10" stroke-miterlimit="10" fill="#065257"/>
<path d="M72.1731 35.9205L94.5304 1.77272L94.8399 1.29652H50.1849L72.1741 35.4424L72.1731 35.4443L50.1826 1.29652H49.8778L50.1826 1.77272L72.1731 35.9205Z" stroke="#ffffff" stroke-width="10" stroke-miterlimit="10" fill="#065257"/>
<path d="M579.706 117.9C573.743 117.9 568.442 116.648 563.804 114.145C559.24 111.568 555.633 108.071 552.982 103.654C550.406 99.2371 549.117 94.1573 549.117 88.415C549.117 83.8506 549.853 79.6911 551.326 75.9365C552.798 72.1819 554.823 68.9427 557.399 66.2188C560.05 63.4212 563.179 61.2863 566.786 59.8139C570.467 58.2679 574.442 57.4949 578.712 57.4949C582.467 57.4949 585.964 58.2311 589.203 59.7035C592.442 61.1022 595.24 63.0531 597.596 65.5562C600.025 68.0593 601.866 71.0408 603.117 74.501C604.442 77.8875 605.068 81.6052 604.994 85.6543L604.884 90.5132H557.51L554.97 81.458H595.056L593.399 83.3353V80.685C593.178 78.2555 592.369 76.0838 590.97 74.1697C589.571 72.2556 587.804 70.7464 585.669 69.6421C583.534 68.5378 581.215 67.9856 578.712 67.9856C574.737 67.9856 571.387 68.7586 568.663 70.3046C565.939 71.777 563.878 73.9856 562.479 76.9304C561.08 79.8016 560.381 83.3721 560.381 87.642C560.381 91.6911 561.228 95.2248 562.921 98.2432C564.614 101.188 567.007 103.47 570.099 105.09C573.191 106.709 576.761 107.519 580.81 107.519C583.681 107.519 586.332 107.041 588.761 106.084C591.264 105.127 593.951 103.397 596.823 100.894L602.565 108.955C600.798 110.722 598.626 112.268 596.05 113.593C593.547 114.918 590.859 115.985 587.988 116.795C585.191 117.532 582.43 117.9 579.706 117.9Z" fill="#ffffff"/>
<path d="M517.982 116.795V44.0222H529.356V116.795H517.982ZM505.945 69.8626V58.8197H542.829V69.8626H505.945Z" fill="#ffffff"/>
<path d="M463.229 117.897C458.444 117.897 454.064 116.572 450.088 113.922C446.186 111.272 443.058 107.664 440.702 103.1C438.346 98.5354 437.168 93.3821 437.168 87.6397C437.168 81.8238 438.346 76.6705 440.702 72.1797C443.131 67.6153 446.37 64.0447 450.419 61.468C454.542 58.8914 459.143 57.603 464.223 57.603C467.241 57.603 470.002 58.0447 472.505 58.9282C475.008 59.8116 477.18 61.0631 479.021 62.6828C480.935 64.2288 482.481 66.0325 483.659 68.0938C484.91 70.1551 485.683 72.3637 485.978 74.7195L483.438 73.8361V58.8177H494.922V116.793H483.438V102.989L486.088 102.216C485.646 104.204 484.726 106.155 483.327 108.069C482.002 109.91 480.272 111.566 478.137 113.038C476.076 114.511 473.757 115.689 471.18 116.572C468.677 117.456 466.027 117.897 463.229 117.897ZM466.211 107.407C469.671 107.407 472.726 106.56 475.376 104.867C478.027 103.173 480.088 100.854 481.56 97.9097C483.106 94.8913 483.879 91.468 483.879 87.6397C483.879 83.8852 483.106 80.5355 481.56 77.5907C480.088 74.6459 478.027 72.3269 475.376 70.6337C472.726 68.9404 469.671 68.0938 466.211 68.0938C462.824 68.0938 459.806 68.9404 457.156 70.6337C454.579 72.3269 452.518 74.6459 450.972 77.5907C449.499 80.5355 448.763 83.8852 448.763 87.6397C448.763 91.468 449.499 94.8913 450.972 97.9097C452.518 100.854 454.579 103.173 457.156 104.867C459.806 106.56 462.824 107.407 466.211 107.407Z" fill="#ffffff"/>
<path d="M339.004 116.798V58.8225H350.489V71.1905L348.39 72.5157C348.979 70.6016 349.9 68.7611 351.151 66.9942C352.476 65.2274 354.059 63.6814 355.9 62.3562C357.814 60.9574 359.838 59.8531 361.973 59.0433C364.182 58.2335 366.427 57.8286 368.709 57.8286C372.022 57.8286 374.93 58.3808 377.433 59.485C379.936 60.5893 381.998 62.2458 383.617 64.4544C385.237 66.6629 386.415 69.4237 387.151 72.7365L385.384 72.2948L386.157 70.4175C386.967 68.7243 388.071 67.1415 389.47 65.6691C390.942 64.1231 392.599 62.7611 394.439 61.5832C396.28 60.4053 398.231 59.485 400.292 58.8225C402.353 58.1599 404.378 57.8286 406.366 57.8286C410.709 57.8286 414.28 58.712 417.077 60.4789C419.948 62.2458 422.083 64.9329 423.482 68.5402C424.955 72.1476 425.691 76.6384 425.691 82.0126V116.798H414.206V82.6752C414.206 79.3623 413.764 76.6752 412.881 74.6138C412.071 72.4789 410.82 70.8961 409.126 69.8654C407.433 68.8347 405.261 68.3194 402.611 68.3194C400.55 68.3194 398.599 68.6875 396.758 69.4237C394.991 70.0862 393.445 71.0433 392.12 72.2948C390.795 73.5464 389.765 75.0188 389.028 76.712C388.292 78.3316 387.924 80.1353 387.924 82.123V116.798H376.439V82.4543C376.439 79.4359 375.998 76.896 375.114 74.8347C374.231 72.6997 372.942 71.0801 371.249 69.9758C369.556 68.8715 367.495 68.3194 365.065 68.3194C363.004 68.3194 361.09 68.6875 359.323 69.4237C357.556 70.0862 356.01 71.0433 354.685 72.2948C353.36 73.4727 352.329 74.9083 351.593 76.6016C350.857 78.2212 350.489 79.9881 350.489 81.9022V116.798H339.004Z" fill="#ffffff"/>
<path d="M315.764 116.797L296.991 92.3917L295.335 90.2936L270.93 58.8213H285.285L303.727 82.8948L305.604 85.3243L329.678 116.797H315.764ZM271.04 116.797L293.899 86.9807L300.635 95.042L284.512 116.797H271.04ZM306.267 88.7476L299.973 80.7967L314.218 58.8213H327.69L306.267 88.7476Z" fill="#ffffff"/>
<path d="M230.678 117.901C226.556 117.901 222.948 116.944 219.856 115.03C216.838 113.116 214.482 110.429 212.789 106.968C211.169 103.508 210.359 99.3856 210.359 94.6003V58.8213H221.734V91.5083C221.734 94.8948 222.249 97.8396 223.28 100.343C224.384 102.772 225.93 104.649 227.918 105.975C229.979 107.3 232.445 107.962 235.316 107.962C237.451 107.962 239.402 107.631 241.169 106.968C242.936 106.232 244.445 105.238 245.697 103.987C247.022 102.735 248.053 101.226 248.789 99.4592C249.525 97.6923 249.893 95.7782 249.893 93.7169V58.8213H261.267V116.797H249.893V104.649L251.881 103.324C250.997 106.048 249.488 108.514 247.353 110.723C245.292 112.932 242.826 114.698 239.954 116.024C237.083 117.275 233.991 117.901 230.678 117.901Z" fill="#ffffff"/>
</svg> */}
                </div>

                <div className="user_design">

                    <div className="user_text">
                        <div className="user_head">
                        <h1>User Experience <br /> Design</h1>       
                        </div>
                        <div className="user_content">
                            <p>Kickstart your career as a user experience designer and product <br />
                             designer with top tech companies</p>
                        </div>
                        <div className="user_tag">
                                <div className="tag_main">
                                    <div className="tag_txt">
                                        <h4>Live</h4>
                                        <small>Sessions on Zoom</small>
                                    </div>
                                    <div className="tag_txt">
                                        <h4>3 months</h4>
                                        <small>Course timeline</small>
                                    </div>
                                    <div className="tag_txt">
                                        <h4>Activities</h4>
                                        <small>based Learning</small>
                                    </div>
                                    <div className="tag_txt">
                                        <h4>Placement</h4>
                                        <small>Support for 1 year</small>
                                    </div>
                                </div>
                        </div>

                    </div>
                    <div className="popup_main">

                        <div className="popup_sub">


                        <div className="pop_up">
                            <div className="dis_img">
                                <img src={disc} alt="discount" />
                            </div>
                            <div className="start_head">
                                <p>Cohort starts on</p>
                                <h4>Mar 18</h4>
                            </div>

                            <div className="fee">
                                <div className='fee_cost'>
                                <p>Fees</p>
                                <h1>₹45,000 <span>₹60,000</span></h1>
                                </div>
                                <div className="fee_emi">
                                <p>Zero Cost EMI Options Available</p>
                                </div>
                            </div>

                            <div className="limited">
                                <p ref={wrong}><i className="fa-solid fa-circle-exclamation"></i> Limited Period Offer <span> {time}sec</span></p>
                            </div>


                        </div>

                    </div>

                    <div className="enroll">
                        <button onClick={formopen} ref={btndsbld}><span>Enrol Now</span><i className="fa-solid fa-arrow-right-long"></i></button>
                    </div>
                    </div>

                </div>

                <div className="form_main" ref={formvis}>

                <div className="form_details">
 
                    <div className="form">
                    <div className="cancel" onClick={formclose}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    {/* <div className="valid_err">
                        <h1>{already}</h1>
                    </div> */}
                    <div className="enroll_head">
                        <h1>Enroll</h1>
                    </div>

                    <div className="inp_fields">

                    <div className="inp_two">

                    <div className="inp">
                    <label htmlFor="a">First name <span>*</span></label><br />
                    <input type="text" placeholder='Eg.John' id='a' onChange={display} value={data.First_Name} name='First_Name' ref={inp1}/>
                    {errfirst? <small>{errfirst}</small>:<small></small>}
                    </div>

                    <div className="inp">
                    <label htmlFor="b">Last name <span>*</span></label><br />
                    <input type="text" placeholder='Eg.Leo' id='b' onChange={display} value={data.Last_Name} name='Last_Name' ref={inp2}/>
                    {errlast && <small>{errlast}</small>}
                    </div>

                    </div>
                    <div className="inp">
                    <label htmlFor="c">Email <span>*</span></label>
                    <input type="email" id='c' placeholder='Eg.John@example.com' onChange={display} value={data.Email} name='Email' ref={inp3}/>
                    {erremail && <small>{erremail}</small>}
                    </div>

                    <div className="inp">
                    <label htmlFor="d">Phone Number <span>*</span></label>
                    <input type="tel" id='d'placeholder='Eg.+91xxxxxxxxxx' maxLength={10} onChange={display} value={data.Phone} name='Phone' ref={inp4}/>
                    {errphone && <small>{errphone}</small>}
                    <p></p>
                    </div>

                    </div>

                    <div className="btn">
                        <button onClick={senddata}>Submit</button>
                    </div>

                    </div>
                    </div>

                </div>

{/* 
                <div className="skill">
                    <div className="skill_head">
                        <h1>Skills you will gain</h1>
                    </div>
                    <ul className='skill_list'>
                        <li><i className="fa-solid fa-square-check"></i> <span>Learn UX & UI in theory and practice</span></li>
                        <li><i className="fa-solid fa-square-check"></i> <span>Explore research methodologies</span></li>
                        <li><i className="fa-solid fa-square-check"></i> <span>Understand real-time tricks and strategies</span></li>
                        <li><i className="fa-solid fa-square-check"></i> <span>Practice new skills with Figma</span></li>
                    </ul>
                </div> */}

                        <div className="home_highlights">
                            <div className="high_text">
                                <h1>Hightlights of the course</h1>
                            </div>
                        <div className='Home_image'>
                        <div className='Home_content'>
                        <img src={plan} alt='no_image'/>
                        <p>Learn UX & UI in<br/>theory and practice</p>
                        </div>
                        <div className='Home_content'>
                        <img src={mag} alt='no_image'/>
                        <p>Explore research<br/>
methodologies</p>
</div>
<div className='Home_content'>
                        <img src={pad} alt='no_image'/>
                        <p>Understand real-time<br/>tricks and strategies</p>
                        </div>
                        <div className='Home_content'>
                        {/* <img src={img4} alt='no_image'/> */}
                        <img src={mntr} alt="" />
                        <p>Practice new skills<br/>with Figma</p>
                        </div>
                    </div>
                    </div>


            </div>

            <div className='design_page_load'>
            <Design btn={formopen}/>
                
            </div>
            
        </section>
    );
};

export default React.memo(Home);