import React  from 'react';
import './Propsdata.css'
const Propsdata = (p) => {



   return (
      
         <div className="props2">
            <div className="props_1">
               <p>{p.add}</p>
               <h1>{p.hd}</h1>
               <small>{p.con}</small>
            </div>
            <div className="props_2">
               <small>{p.time}</small>
               <p>{p.sess}</p>
            </div>
      </div>
     


   );
};

export default Propsdata;