import React from 'react';
import Propsdata from './Propsdata';
import './Addon.css'
// import Proplearn from './Proplearn';
// import Proplearn from './Proplearn';

const Addon = () => {
    const joinmeet = ()=>{
        window.location.href = 'https://us06web.zoom.us/meeting/register/tZYqc-uorjktG9DUteBoefk6LKRbTyspFp_N'
    }
    
    return (
        <div className='add'>
            <div className="props2_head">
                <h1><span>Add-ons</span> to add more value to your learnings</h1>
            </div>


            <div className="add_on">
            <Propsdata add={'#AddOn'} hd={'Mentor Labs'} 
            con={'Guidance on design queries and real-time case study discussions'}
            time={'Time spent'} sess={'3 sessions during the course'}/>

            <Propsdata add={'#AddOn'} hd={'Mock Interviews'} 
            con={`Interviews by Industry experts to prep you up for real-time job interviews`}
            time={'Time spent'} sess={'2-3 sessions during the course'}/>
            
            <Propsdata add={'#AddOn'} hd={'Lead Activities'} 
            con={'Leading activities will prep you up for real-time research activities'}
            time={'Time spent'} sess={'During your learning in the classrooms'}/>
            </div>


            <div className="lpa">
                <div className="lpa_head">
                    <h1>What is it going to be like?</h1>
                </div>

                <div className="lpa_text">
                    <p>User Experience Designer / Product Designer</p>
                    <h1>8 LPA</h1>
                    <small>Starting Package *</small>
                </div>
            </div>

            <div className="council">
                <div className="council_head">
                    <h1>Get <span>FREE</span> Counselling Session</h1>
                </div>

                <div className="council_txt_main">


                <div className="council_txt">
                    <p>Uxmate brings Free Guidance sessions with <span>Star Faculties</span> of Uxmate.</p>
                    <p>Join our Online FREE MasterClass to Learn More about</p>
                </div>
                <div className="council_txt2">
                    {/* <div className="cncl1"> */}
                        <p><i className="fa-solid fa-square-check"></i> <span>Who is UI & UX Designer</span></p>
                        <p><i className="fa-solid fa-square-check"></i> <span>How to Crack Your First Job with 3 to 12 LPA</span></p>
                    {/* </div> */}
  
                    {/* <div className="cncl1"> */}
                        <p><i className="fa-solid fa-square-check"></i> <span>Know More about UI & UX Designer Career Graph, Salaries, Hiring trends etc</span></p>
                        <p><i className="fa-solid fa-square-check"></i> <span>How to become a professional UI & UX Designer in 100 Days</span></p>
                    {/* </div> */}

                </div>
                <div className="council_btn">
                    <button onClick={joinmeet}>Register The Session</button>
                </div>

                </div>

            </div>

        </div>
    );
};

export default Addon;