import React, { useRef, useState }  from 'react';
// import { AiOutlineDownload } from "react-icons/ai";
import './Design.css'
import Props from './Props';
import axios from 'axios';
// import { saveAs } from 'file-saver';
// import jsPDF from 'jspdf';
// import pdf from 'Curriculum.pdf'
// import pdf from '../Pages/Images/Curriculum.pdf'
const Design = ({btn}) => {

  // const dwld = ()=>{

  //   const url = pdf

  //   const doc = new jsPDF()
  //   doc.text('./Images/Curriculum.pdf')
  //   doc.save()

    
  // }
  const banner_open = useRef()



  window.onscroll =()=>{


    if(window.scrollY<=550){
      banner_open.current.classList.remove('bn_open')
    }
    else{
      if(window.scrollY<=3340){
        banner_open.current.classList.add('bn_open')
      }
      else{
        banner_open.current.classList.remove('bn_open')
      }
    }
  }



// if(window.screenX <=1400){
//   window.onscroll =()=>{
//     if(window.scrollY<=750){
//       banner_open.current.classList.remove('bn_open')
//     }
//     else{
//       if(window.scrollY<=3340){
//         banner_open.current.classList.add('bn_open')
//       }
//       else{
//         banner_open.current.classList.remove('bn_open')
//       }
//     }
//   }
// }


  const [data,setdata] = useState({
    First_Name:'',
    Last_Name:'',
    Email:'',
    Phone:''
})
const formvis = useRef()


const [errfirst,seterrfirst] = useState('')
const [errlast,seterrlast] = useState('')
const [erremail,seterremail] = useState('')
const [errphone,seterrphone] = useState('')

const inp1 = useRef()
const inp2 = useRef()
const inp3 = useRef()
const inp4 = useRef()


const formopen = ()=>{
  formvis.current.classList.add('vis')
}
function formclose(){
  formvis.current.classList.remove('vis')
}

function display(e){
  setdata({...data,[e.target.name]:e.target.value})
}

function senddata(){

  let valid = true
  
  if(inp1.current.value.trim() === ''){
      seterrfirst('First name is required')
      valid = false
  }
  else{
      seterrfirst('')
      valid = true
  }

  if(inp2.current.value.trim() === ''){
      seterrlast('Last name is required')
      valid = false
  }
  else{
      seterrlast('')
      valid = true
  }

  if (data.Email.trim() === '') {
      seterremail('Email is required')
      valid = false
  } 
  else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.Email)) {
      seterremail('Invalid email format')
      valid = false
  }
  else{
      seterremail('')
      valid = true
  }
  
  if (data.Phone.trim() === '') {
      seterrphone('Phone number is required')
      valid = false
  } else if (!/^\d{10}$/.test(data.Phone)) {
      seterrphone('Invalid phone number')
      valid = false
  }
  else{
      seterrphone('')
      valid = true
    }


    if(valid === true){
      
      axios.post('https://sheet.best/api/sheets/2042a39a-82f2-4594-87e7-24c463be941b',data)
      .then(()=>alert('Submit Successfully'))
      setdata({First_Name:'',Last_Name:'',Email:'',Phone:''})
      formvis.current.classList.remove('vis')

      const pdfUrl = "Curriculum.pdf";
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "Curriculum.pdf"; // specify the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }

}

     
  //   const onButtonClick = () => {
  //     const pdfUrl = "Curriculum.pdf";
  //     const link = document.createElement("a");
  //     link.href = pdfUrl;
  //     link.download = "Curriculum.pdf"; // specify the filename
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  // };

    return (
        <div className='Learn_page'>
            <div className='Learn_callback'>
               <p>What will you learn?</p>
               <div className='req_main'>
               <button className='req' onClick={btn}><i className="fa-solid fa-phone"></i><span>Request a callback</span></button>
               </div>

            </div>
            <div className='curriculum_By'>
               <h1>Curriculum crafted by <span>ISB & IIM Alumni Experts</span> </h1>
            </div>

                  <div className='props_load'>
                    <Props/>

                    <div className="enrol_banner" ref={banner_open}>
                          <div className="banner">
                              <p>Cohort starts on</p>
                              <h3>Mar 18</h3>
                          </div>
                          <div className="banner">
                              <p>Time per week</p>
                              <h3>8-10 hours/week</h3>
                          </div>
                          <div className="banner">
                              <p>Fees</p>
                              <h3>45,000 <span>60,000</span></h3>
                          </div>
                          <div className="butn">
                            <button onClick={btn}><span>Enrol Now</span><i className="fa-solid fa-arrow-right-long"></i></button>
                          </div>
                    </div>
                    
                  </div>

                  <div className='Button_download'>
                    <button onClick={formopen}><span>Download Curriculum</span><i className='bx bx-download'></i></button>
                  </div>

                  <div className="con_adv">
                    <button onClick={()=>window.open('tel:+91 8019479419')}><i className="fa-solid fa-phone"></i> Contact advisor</button>
                    <button onClick={formopen}>Download syllabus</button>
                  </div>




                <div className="form_main" ref={formvis}>

                <div className="form_details">
 
                    <div className="form">
                    <div className="cancel" onClick={formclose}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    {/* <div className="valid_err">
                        <h1>{already}</h1>
                    </div> */}
                    <div className="enroll_head">
                        <h1>Fill the Details to View Curriculum</h1>
                    </div>

                    <div className="inp_fields">

                    <div className="inp_two">

                    <div className="inp">
                    <label htmlFor="a">First name <span>*</span></label><br />
                    <input type="text" placeholder='Eg.John' id='a' onChange={display} value={data.First_Name} name='First_Name' ref={inp1}/>
                    {errfirst? <small>{errfirst}</small>:<small></small>}
                    </div>

                    <div className="inp">
                    <label htmlFor="b">Last name <span>*</span></label><br />
                    <input type="text" placeholder='Eg.Leo' id='b' onChange={display} value={data.Last_Name} name='Last_Name' ref={inp2}/>
                    {errlast && <small>{errlast}</small>}
                    </div>

                    </div>
                    <div className="inp">
                    <label htmlFor="c">Email <span>*</span></label>
                    <input type="email" id='c' placeholder='Eg.John@example.com' onChange={display} value={data.Email} name='Email' ref={inp3}/>
                    {erremail && <small>{erremail}</small>}
                    </div>

                    <div className="inp">
                    <label htmlFor="d">Phone Number <span>*</span></label>
                    <input type="tel" id='d'placeholder='Eg.+91xxxxxxxxxx' maxLength={10} onChange={display} value={data.Phone} name='Phone' ref={inp4}/>
                    {errphone && <small>{errphone}</small>}
                    <p></p>
                    </div>

                    </div>

                    <div className="btn">
                        <button onClick={senddata}>View Curriculum</button>
                    </div>

                    </div>
                    </div>

                </div>
        </div>
    );
};

export default Design;