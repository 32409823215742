import React from 'react';
// import "./Styles/Tools.css"
import './Tools.css'
// import img1 from './Images/figma.png'
import img1 from './Images/figma.png'

const Tools = () => {
    return (
        <div className='Tools_Learn'>
            <div className='Tools_head'>
                <h1>Tools You <span>Will Learn</span></h1>
            </div>
            <div className='Tools_icon'>
               <div className='tool_img'>
                <img src={img1} alt="" />
               </div>
               <div className='tool_img'>
                <img src='https://cdn.worldvectorlogo.com/logos/miro-2.svg' alt=''/>
               </div>
               <div className='tool_img'>
                <img src='https://logolook.net/wp-content/uploads/2023/04/Jira-Logo-2017.png' alt=''/>
               </div>
               <div className='tool_img'>
                <img src='https://kdouglas011.files.wordpress.com/2019/10/logo_lockup_analytics_icon_vertical_black_2x.png?w=1024' alt=''/>
               </div>
               <div className='tool_img'>
                <img src='https://cdn-fhegp.nitrocdn.com/kretwvZEDVkoxhyZQIjyyOBiegJKpPMo/assets/images/optimized/rev-98d5bcc/wp-content/uploads/2021/04/GFLogo-1024x965.png' alt=''/>
               </div>
            </div>
        </div>
    );
};

export default Tools;